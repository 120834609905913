import React from 'react'
import { Link, PageProps } from 'gatsby'
import { Head, Nav, Footer } from '~/components'
import { useApi } from '~/api'
import * as st from '~/assets/styl/PrivacyPoliticPage.module.styl'
import cn from 'classnames'

type PageContext = {
  name: string
  content: string
  slug: string
  default: boolean
  url: string
}

const PrivacyPolitic = ({ location, pageContext }: PageProps) => {
  const { name, content } = useApi(
    pageContext,
    `pages/${(pageContext as PageContext).slug}`
  ) as PageContext

  return (
    <>
      <Head
        location={location}
        title={name + ' - ' + process.env.GATSBY_SITE_NAME}
      />
      <Nav location={location} />
      <div className={cn(st.core, st.content1364)}>
        <section className={st.flex}>
          <h1 className={name === 'Termos de uso' && st.customWidth}>{name}</h1>
          <div dangerouslySetInnerHTML={{ __html: content }}></div>
        </section>
      </div>
      <Footer />
    </>
  )
}

export default PrivacyPolitic
